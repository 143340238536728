import { useAppStore } from '@/store'
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'
const store = useAppStore()
/**弹出选择模态框 */
export function showModel(options: {
    title?: string;
    msg: string;
    success?: any;
    fail?: any;
}) {
    const { msg, title, success, fail } = options
    ElMessageBox.confirm(
        msg,
        title,
        {
            confirmButtonText: store.tMapState('confirm'),
            cancelButtonText: store.tMapState('cancel'),
            type: 'warning',
        }
    )
        .then(() => {
            if (success)
                success()
        })
        .catch(() => {
            if (fail)
                fail()
        })
}

/**弹出提示 */
export function showTip(msg: string | any, type?: 'success' | 'warning' | 'error') {
    ElMessage({
        message: msg,
        type: type ?? 'success',
    })
}

/**等待 */
export function containerLoading(text: string, callBack: (e: any) => any) {
    const loading = ElLoading.service({
        lock: true,
        text: text ?? 'Loading',
        background: 'rgba(0, 0, 0, 0.5)',
    })
    callBack(loading.close)
}